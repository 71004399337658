var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-chip',_vm._g(_vm._b({staticClass:"ma-1",class:{
        waiting: _vm.order.statusID === 1,
        'in-progress': _vm.order.statusID === 2,
        complete: _vm.order.statusID === 3,
      },attrs:{"dark":""},on:{"click":function($event){return _vm.onChipClick(_vm.order)}}},'v-chip',attrs,false),on),[_vm._v(" "+_vm._s(_vm.order.name)+" ")])]}}])},[_c('span',{domProps:{"innerHTML":_vm._s(_vm.order.tooltip)}})])}
var staticRenderFns = []

export { render, staticRenderFns }