import moment from "moment";

export const nameRules = [
  v => !!v || 'Name is required',
]
export const emailRules = [
  v => !!v || 'E-mail is required',
  v => /.+@.+/.test(v) || 'E-mail not valid',
]
export const dateRules = [
  v => !!v || 'Date is required',
  v => v != "Invalid date" || "Formated to DD/MM/YYYY"
]
export const dateFormatedRules = [
  v => !!v || 'Date is required',
  v => moment(v, "DD/MM/YYYY").isValid() || "Formated to DD/MM/YYYY"
]
export const requiredRules = [
  v => !!v || 'Required field',
]
export const phoneRules = [
  v => !!v || 'Required field',
  v => v && v.length > 9 || 'Minimum 10 digits',
  v => /^(0|[0-9]\d*)$/.test(v) || 'Accept numbers only',
]
export const identityNumberRules = [
  v => !!v || 'Required field',
  v => v && v.length > 8 || 'Minimum 9 digits',
  v => /^(0|[0-9]\d*)$/.test(v) || 'Accept numbers only',
]