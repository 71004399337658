<template>
  <div class="viewer pa-0 ma-0">
    <PatientInfoLine ref="PatientInfoLine" />
    <AdmissionInfoLine
      @onAdmissionLoaded="handleAdmissionLoaded"
      class="normal-pt"
    />
    <vue-tabs-chrome
      ref="PatientTab"
      v-model="patientTab.selected"
      @remove="handleRemoveEpisodeRecordTab"
      :class="{ 'normal-pt': !$route.query.episodeRecordID }"
      :tabs="patientTab.options"
      :draggable="false"
      :swappable="false"
    />
    <iframe
      v-for="iframe in iframes"
      :id="iframe.key"
      :key="iframe.key"
      :src="iframe.key"
      v-show="patientTab.selected == iframe.key"
      class="window-view"
      :style="{
        height: `calc(100vh - ${
          $route.query.episodeRecordID ? '134' : '80'
        }px)`,
      }"
    />
  </div>
</template>

<script>
import PatientInfoLine from "@/components/common/Patient/PatientInfoLine";
import AdmissionInfoLine from "@/components/common/Admission/AdmissionInfoLine";
import EpisodeRecordService from "@/services/episodeRecord";
import moment from "moment";
import i18n from "@/plugins/i18n";

export default {
  data() {
    return {
      patientObj: {},
      patientTab: {
        selected: `${this.$route.path}/overview`,
        options: [
          {
            key: `${this.$route.path}/overview`,
            label: i18n.t("common.overview"),
            closable: false,
          },
          {
            key: `${this.$route.path}/progress`,
            label: i18n.t("common.Progress"),
            closable: false,
          },
          {
            key: `${this.$route.path}/analysis`,
            label: i18n.t("common.Analysis"),
            closable: false,
          },
        ],
      },
      iframes: [],
    };
  },
  components: {
    PatientInfoLine,
    AdmissionInfoLine,
  },
  async mounted() {
    // seperate iframes and tabs will increase performance drastically
    this.iframes = JSON.parse(JSON.stringify(this.patientTab.options));
    this.sockets.common.on(
      "EpisodeRecordCanceled",
      this.onEpisodeRecordCanceled
    );
    window.addEventListener("message", (e) => {
      if (e.data.event === "ADD_EPISODE_RECORD_TAB") {
        this.handleAddEpisodeRecordTab(e.data.data);
      }
    });
  },
  methods: {
    async onEpisodeRecordCanceled(episodeRecordID) {
      var patientID = this.$route.params.patientId;
      var id = `${patientID}-${episodeRecordID}`;
      var ind = this.iframes.findIndex((i) => i.id == id);
      if (ind > -1) {
        this.iframes.splice(ind, 1);
        this.$refs.PatientTab.removeTab(ind);
        this.$toast.warning("Episode Closed due to Admin deletion!");
      }
    },
    async handleAdmissionLoaded({ admissionInfo, episodeRecordID }) {
      var { admissionTime } = admissionInfo;
      var result = await EpisodeRecordService.getByID(episodeRecordID);
      if (!result || result.error) return;
      var { statusID } = result;
      if (statusID == -1) return;
      var label = moment(admissionTime).format("DD/MM/YYYY");
      var patientID = this.$route.params.patientId;
      var key = `/patient/${patientID}/episodeRecord/${episodeRecordID}/chief-complaints`;
      var tab = {
        id: `${patientID}-${episodeRecordID}`,
        key,
        label,
      };
      this.iframes.push(tab);
      this.$refs.PatientTab.addTab(tab);
    },
    // add header tab
    handleAddEpisodeRecordTab({ key, label }) {
      // if this tab already open => do nothing
      if (this.iframes.find((f) => f.key == key)) {
        this.patientTab.selected = key;
        return;
      }
      var tab = { key, label, closable: true };
      this.iframes.push(tab);
      this.$refs.PatientTab.addTab(tab);
      this.patientTab.selected = key;
    },
    // when remove any tab => save current config
    handleRemoveEpisodeRecordTab({ key }) {
      // find and remove the iframe
      var ind = this.iframes.findIndex((f) => f.key == key);
      this.iframes.splice(ind, 1);
    },
  },
};
</script>

<style lang="scss">
.patient-divide {
  height: 24px;
  color: $neutral-30;
}
.normal-pt {
  margin-top: 8px;
}
.viewer {
  width: 100vw;
  height: 100vh;
  overflow: hidden !important;

  .vue-tabs-chrome {
    background: white;
    padding-top: 0px;

    .tabs-footer {
      height: 0px !important;
    }
    .tabs-close {
      right: 22px;
    }
    .tabs-item.active {
      .tabs-main {
        background: #bdbdbd;
      }
    }
  }
  iframe.window-view {
    outline: none;
    border: none;
    width: 100vw;
    display: inline-block;
    overflow-x: hidden;
    overflow-y: scroll;
  }
}
</style>
