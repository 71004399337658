<template>
  <v-row class="px-6 pt-0 mt-0 align-baseline">
    <div class="patient-info-line">
      <AvatarItem
        :imageUrl="patientInfo.avatar"
        :fullName="patientInfo.fullName"
      />
      <span class="font-weight-bold">
        {{ patientInfo.fullName }}
      </span>
      <v-divider vertical class="patient-divide mx-4" />
      ID: <span class="font-weight-bold">{{ patientInfo.patientID }}</span>
      <v-divider vertical class="patient-divide mx-4" />
      <span v-if="patientInfo.athenaID">
        AID: <span class="font-weight-bold">{{ patientInfo.athenaID }}</span>
        <v-divider vertical class="patient-divide mx-4" />
      </span>
      {{ $t("main.patientInfoLine.DOB") }}:
      <span class="font-weight-bold">{{
        formatDate(patientInfo.birthDate)
      }}</span>
      <v-divider vertical class="patient-divide mx-4" />
      {{ $t("main.patientInfoLine.phone") }}:
      <span class="font-weight-bold">{{ patientInfo.phone }}</span>
      <v-divider vertical class="patient-divide mx-4" />
      {{ $t("main.patientInfoLine.gender") }}:
      <GenderItem :genderType="patientInfo.genderType" />
      <v-divider vertical class="patient-divide mx-4" />
      {{ $t("main.patientInfoLine.address") }}:
      <v-tooltip bottom>
        <template v-slot:activator="{ on, attrs }">
          <span
            v-bind="attrs"
            v-on="on"
            class="font-weight-bold mw-600"
            v-html="patientInfo.address || 'None'"
          ></span>
        </template>
        <span
          class="font-weight-bold"
          v-html="patientInfo.address || 'None'"
        ></span>
      </v-tooltip>
    </div>
    <div class="text-right patient-eye-container">
      <v-btn
        @click="showPatientProfilePopup"
        icon
        color="blue-grey"
        class="white--text pa-2 mr-2"
      >
        <v-icon>mdi-eye</v-icon>
      </v-btn>
    </div>
    <PatientProfilePopup ref="PatientProfilePopup" />
  </v-row>
</template>

<script>
import moment from "moment";
import GenderItem from "@/components/GenderItem";
import PatientService from "@/services/patient";
import AvatarItem from "@/components/AvatarItem.vue";
import PatientProfilePopup from "./components/PatientProfilePopup.vue";

export default {
  props: {
    patientID: {
      type: Number,
      default: function () {
        return parseInt(this.$route.params.patientId || 0);
      },
    },
  },
  data() {
    return {
      patientInfo: {},
    };
  },
  components: {
    AvatarItem,
    GenderItem,
    PatientProfilePopup,
  },
  async mounted() {
    this.getPatientInfo();
  },
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
    showError(message) {
      this.$toast.error(message);
    },
    showPatientProfilePopup() {
      this.$refs.PatientProfilePopup.openPopup(this.patientID);
    },
    async getPatientInfo() {
      const apiResult = await PatientService.getPatientDetail(this.patientID);
      if (apiResult.error) {
        this.showError(
          "Can not get patient information. Please try again later."
        );
        return;
      }
      var { patientIDString, personalInfo } = apiResult;
      this.patientInfo = {
        ...personalInfo,
        patientID: patientIDString,
      };
    },
  },
};
</script>

<style lang="scss">
.patient-divide {
  height: 24px;
  color: $neutral-30;
}
.theme--light.v-input--is-disabled * {
  color: rgba(0, 0, 0, 1) !important;
}
.patient-info-line {
  width: calc(100% - 60px - 10px);
  display: inline-block;
  overflow: hidden;
  height: 49px;
  padding-top: 8px;
  padding-bottom: 26px;
  margin-left: 10px;
}
.patient-eye-container {
  width: calc(60px);
  display: inline-block;
}
</style>
