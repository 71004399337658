<template>
  <v-avatar color="grey fl mr-4" size="36">
    <v-img v-if="imageUrl" alt="Avatar" :src="imageUrl"></v-img>
    <span v-else class="white--text">
      {{ getNameInnitial(fullName) }}
    </span>
  </v-avatar>
</template>

<script>
import { getNameInnitial } from "@/plugins/helper";

export default {
  props: {
    imageUrl: {
      type: String,
      default: null,
    },
    fullName: {
      type: String,
      default: null,
    },
  },
  data() {
    return {
      getNameInnitial,
    };
  },
};
</script>