<template>
  <v-row>
    <v-col cols="6">
      <v-text-field
        :disabled="!editable"
        :label="$t('relativeInfo.relativeName')"
        v-model="relativeInfo.name"
      >
      </v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        :disabled="!editable"
        label="Email"
        v-model="relativeInfo.email"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        :disabled="!editable"
        :label="$t('relativeInfo.address')"
        v-model="relativeInfo.address"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        :disabled="!editable"
        :label="$t('relativeInfo.relationship')"
        v-model="relativeInfo.relationship"
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-select
        :disabled="!editable"
        v-model="relativeInfo.gender"
        :label="$t('relativeInfo.genderType')"
        :items="GenderTypeCodes"
      ></v-select>
    </v-col>
    <v-col cols="4">
      <v-text-field
        :disabled="!editable"
        :label="$t('relativeInfo.phone')"
        v-model="relativeInfo.phone"
      ></v-text-field>
    </v-col>
    <v-col cols="4">
      <v-menu
        :disabled="!editable"
        :close-on-content-click="false"
        transition="scale-transition"
        offset-y
        min-width="290px"
        ref="dobMenu"
        v-model="dobMenu"
      >
        <template v-slot:activator="{ on, attrs }">
          <v-text-field
            :disabled="!editable"
            v-model="dobText"
            :label="$t('relativeInfo.DOB')"
            append-icon="mdi-calendar"
            v-bind="attrs"
            v-on="on"
          ></v-text-field>
        </template>
        <v-date-picker
          :disabled="!editable"
          no-title
          scrollable
          v-model="relativeInfo.dob"
        >
          <v-spacer></v-spacer>
          <v-btn
            :disabled="!editable"
            text
            color="primary"
            @click="$refs.dobMenu.save(relativeInfo.dob)"
          >
            OK
          </v-btn>
        </v-date-picker>
      </v-menu>
    </v-col>
  </v-row>
</template>

<script>
import moment from "moment";
import { getGenderStatusCodeOptions } from "@/plugins/helper";

export default {
  props: {
    relativeInfo: {
      type: Object,
      default: () => ({
        patientRelativeID: 0,
        patientID: 0,
        name: "",
        gender: "",
        address: "",
        phone: "",
        email: "",
        dob: "",
        relationship: "",
      }),
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return { dobMenu: false };
  },
  computed: {
    GenderTypeCodes: () => getGenderStatusCodeOptions(),
    dobText() {
      if (!this.relativeInfo.dob) return "";
      return moment(this.relativeInfo.dob).format("YYYY-MM-DD");
    },
  },
  watch: {
    // emit change event to parent
    relativeInfo: {
      deep: true,
      handler(val) {
        this.$emit("onChange", val);
      },
    },
  },
  components: {},
  async created() {},
  methods: {
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("DD-MM-YYYY");
    },
  },
};
</script>
<style scoped>
</style>