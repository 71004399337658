<template>
  <v-row>
    <v-col cols="4" style="text-align: center">
      <v-avatar color="grey lighten-2" size="180">
        <v-img v-if="personalInfo.avatar" :src="personalInfo.avatar"></v-img>
        <v-icon v-else dark size="120px"> mdi-image-outline </v-icon>
      </v-avatar>
      <br />
      <v-btn
        v-if="editable"
        :loading="uploading"
        :disabled="uploading"
        color="primary"
        class="mt-2 pa-1 pr-2"
        @click="triggerFileUpload"
      >
        <v-icon right dark class="mr-2"> mdi-cloud-upload </v-icon>
        {{ $t("personalInfo.selectAvatar") }}
      </v-btn>
      <v-file-input
        v-if="editable"
        id="select-file"
        style="margin-top: -40px; display: none; cursor: pointer; opacity: 0"
        @change="handleFileUpload()"
        accept="image/*"
        v-model="file"
        truncate-length="15"
      ></v-file-input>
      <v-btn
        v-if="editable"
        :loading="uploading"
        :disabled="uploading"
        class="mt-2 pa-1 pr-2"
        @click="showDialog = true"
      >
        <v-icon right dark class="mr-2"> mdi-camera </v-icon>
        {{ $t("personalInfo.Chụp hình") }}
      </v-btn>
      <span v-else>
        <h3 class="mt-2">{{ personalInfo.fullName }}</h3>
        <h4 v-if="personalInfo.patientIDString" class="mt-2">
          PID: {{ personalInfo.patientIDString }}
        </h4>
        <h4 v-if="personalInfo.athenaID" class="mt-2">
          AID: {{ personalInfo.athenaID }}
        </h4>
      </span>
      <!-- Image capturing dialog -->
      <v-dialog v-model="showDialog" max-width="400px">
        <v-card>
          <v-row>
            <v-col>
              <div>
                <video
                  ref="video"
                  id="video"
                  width="400"
                  height="300"
                  autoplay
                ></video>
              </div>
            </v-col>
            <canvas
              ref="canvas"
              id="canvas"
              width="400"
              height="300"
              style="display: none"
            ></canvas>
            <v-col align="center">
              <v-btn
                color="primary"
                dense
                @click="capture(), (showDialog = false)"
              >
                Take Picture
              </v-btn>
            </v-col>
          </v-row>
        </v-card>
      </v-dialog>
    </v-col>
    <v-col cols="8">
      <v-row>
        <v-col cols="9">
          <v-text-field
            :disabled="!editable"
            :rules="nameRules"
            :label="$t('personalInfo.fullName')"
            v-model="personalInfo.fullName"
          >
          </v-text-field>
        </v-col>
        <v-col cols="3">
          <v-text-field
            :disabled="!editable"
            label="Athena ID"
            v-model="personalInfo.athenaID"
          >
          </v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="6">
          <v-select
            :disabled="!editable"
            :rules="requiredRules"
            v-model="personalInfo.genderType"
            :label="$t('personalInfo.genderType')"
            :items="GenderTypeCodes"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-text-field
            v-show="!editable"
            :disabled="true"
            :value="formatDob(personalInfo.birthDate)"
            :label="$t('personalInfo.DOB')"
            append-icon="mdi-calendar"
            v-mask="'##/##/####'"
            placeholder="dd/mm/yyyy"
          ></v-text-field>
          <v-text-field
            v-show="editable"
            v-model="dobEdit"
            :rules="dateRules"
            @change="convertBirthdateString"
            :label="$t('personalInfo.DOB')"
            append-icon="mdi-calendar"
            v-mask="'##/##/####'"
            placeholder="dd/mm/yyyy"
          ></v-text-field>
        </v-col>
        <v-col cols="12">
          <v-text-field
            :disabled="!editable"
            label="Email"
            v-model="personalInfo.email"
          ></v-text-field>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12">
      <v-text-field
        :disabled="!editable"
        :label="$t('personalInfo.address')"
        v-model="personalInfo.address"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        :disabled="!editable"
        :label="$t('personalInfo.phone')"
        v-mask="'+## ### ### ###'"
        v-model="personalInfo.phone"
      ></v-text-field>
    </v-col>
    <v-col cols="6">
      <v-text-field
        :disabled="!editable"
        v-mask="'## ### ####'"
        :label="$t('personalInfo.id')"
        v-model="personalInfo.identificationNumber"
      ></v-text-field>
    </v-col>
    <v-col cols="12">
      <v-autocomplete
        @keydown.enter="checkOrCreateOccupation"
        :label="$t('personalInfo.occupation')"
        :disabled="!editable"
        v-model="personalInfo.occupationID"
        :items="listOccupationOptions"
      >
        <template v-slot:no-data>
          <span class="ma-2">Press enter to use this occupation</span>
        </template>
      </v-autocomplete>
    </v-col>
    <v-col cols="6">
      <v-autocomplete
        :label="$t('personalInfo.country')"
        :disabled="!editable"
        v-model="personalInfo.nationalityID"
        :items="listNationalityOptions"
      ></v-autocomplete>
    </v-col>
    <v-col cols="6">
      <v-autocomplete
        @keydown.enter="checkOrCreateEthicity"
        :disabled="!editable"
        :label="$t('personalInfo.ethnicity')"
        v-model="personalInfo.ethnicityID"
        :items="listEthnicityOptions"
      >
        <template v-slot:no-data>
          <span class="ma-2">{{ $t("personalInfo.selectEth") }}</span>
        </template>
      </v-autocomplete>
    </v-col>
    <DialogConfirm ref="DialogConfirm" :message="dialogMessage" />
  </v-row>
</template>

<script>
import moment from "moment";
import PatientService from "@/services/patient";
import LookupService from "@/services/lookup";
import FirebaseStorage from "@/services/firebase.storage/firebase.storage";
import { getGenderStatusCodeOptions } from "@/plugins/helper";
import {
  nameRules,
  emailRules,
  dateRules,
  requiredRules,
  phoneRules,
  identityNumberRules,
} from "@/plugins/rules";
import DialogConfirm from "@/components/DialogConfirm";

export default {
  props: {
    personalInfo: {
      type: Object,
      default: () => {
        return {
          patientID: 0,
          athenaID: null,
          fullName: "",
          phone: "",
          email: "",
          address: "",
          birthDate: "",
          avatar: "",
          genderType: 1,
          identificationNumber: "",
          occupationID: null,
          nationalityID: null,
          ethnicityID: null,
        };
      },
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    DialogConfirm,
  },
  data() {
    return {
      nameRules,
      emailRules,
      dateRules,
      requiredRules,
      phoneRules,
      identityNumberRules,
      dialogMessage: "",
      status: {
        type: "error",
        show: false,
        message: "",
      },
      video: {},
      canvas: {},
      captures: [],
      showDialog: false,
      rules: [
        (value) =>
          !value ||
          value.size < 2000000 ||
          "Avatar size should be less than 2 MB!",
      ],
      uploading: false,
      dobMenu: false,
      toDataURL: null,
      file: null,
      listNationalityOptions: [],
      listOccupationOptions: [],
      listEthnicityOptions: [],
      dobEdit: "",
    };
  },
  updated() {
    this.video = document.getElementById("video");
    this.canvas = document.getElementById("canvas");
    if (this.showDialog == true) {
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        navigator.mediaDevices
          .getUserMedia({ video: true, audio: false })
          .then((stream) => {
            this.video.srcObject = stream;
            this.video.play();
          });
      }
    } else if (this.video != null && this.showDialog == false) {
      const mediaStream = this.video.srcObject;
      const tracks = mediaStream.getTracks();
      tracks.forEach((track) => track.stop());
    }
  },
  watch: {
    // emit change event to parent
    personalInfo: {
      deep: true,
      handler(val) {
        // default gender type = 1
        if (!val.genderType) {
          val.genderType = 1;
        }
        // update text dobEdit
        if (val.birthDate) {
          this.dobEdit = moment(val.birthDate).format("DD/MM/YYYY");
        }
        this.personalInfo.fullName = this.getFullName();
        this.$emit("onChange", val);
        // return model
        this.$emit("input", this.personalInfo);
      },
    },
  },
  computed: {
    GenderTypeCodes: () => getGenderStatusCodeOptions(),
  },
  async mounted() {
    this.renderListNationalityOptions();
    this.renderListEthnicityOptions();
    this.renderListOccupationOptions();
  },
  methods: {
    async checkOrCreateOccupation(e) {
      var element = document.getElementById(e.target.id);
      var name = element.value;
      this.dialogMessage = "Do you want to use this occupation?";
      if (!(await this.$refs.DialogConfirm.confirm())) return;
      var result = await LookupService.checkOrCreateOccupation(name);
      if (!result || result.error) {
        this.showError("Cannot create new occupation. Please try again later");
        return;
      }
      this.listOccupationOptions.push({
        text: result.occupationDescription,
        value: result.occupationID,
      });
      this.personalInfo.occupationID = result.occupationID;
    },
    async checkOrCreateEthicity(e) {
      var element = document.getElementById(e.target.id);
      var name = element.value;
      this.dialogMessage = "Do you want to use this ethicity?";
      if (!(await this.$refs.DialogConfirm.confirm())) return;
      var result = await LookupService.checkOrCreateEthnicity(name);
      if (!result || result.error) {
        this.showError("Cannot create new ethicity. Please try again later");
        return;
      }
      this.listEthnicityOptions.push({
        text: result.ethnicityCode,
        value: result.ethnicityID,
      });
      this.personalInfo.ethnicityID = result.ethnicityID;
    },
    capture() {
      this.canvas = this.$refs.canvas;
      var context = this.canvas.getContext("2d");
      context.drawImage(this.video, 0, 0, 400, 300);
      this.personalInfo.avatar = this.canvas.toDataURL();
      this.handleCaptureUpload(this.canvas.toDataURL());
    },
    formatDob(datestr) {
      if (!datestr) return "";
      return moment(datestr).format("DD/MM/YYYY");
    },
    convertBirthdateString() {
      var dateStr = moment(this.dobEdit, "DD/MM/YYYY").format("YYYY-MM-DD");
      if (dateStr == "invalid date") return;
      this.personalInfo.birthDate = dateStr;
    },
    showError(message) {
      this.$toast.error(message);
    },
    triggerFileUpload: () => document.querySelector("#select-file").click(),
    async handleFileUpload() {
      if (!this.file) {
        return;
      }
      var { name } = this.file;
      var path = `patients/avatars/${name}`;
      this.uploading = true;
      var downloadUrl = await FirebaseStorage.singleUpload(this.file, path);
      this.uploading = false;
      if (downloadUrl) this.personalInfo.avatar = downloadUrl;
    },
    async handleCaptureUpload(file) {
      if (!file) return;
      this.uploading = true;
      var fileName = Date().toString + ".png";
      await FirebaseStorage.uploadImageFile(file, fileName).then(
        (downloadURL) => (this.personalInfo.avatar = downloadURL)
      );
      this.uploading = false;
    },
    async renderListNationalityOptions() {
      var result = await PatientService.getNationalityOption("", 1, 1000);
      if (result.error) {
        this.showError("Can not get list nationality. Please try again later.");
        return;
      }
      this.listNationalityOptions = result.items.map((i) => ({
        text: i.nationalityDescription,
        value: i.nationalityID,
      }));
    },
    async renderListEthnicityOptions() {
      var result = await PatientService.getEthnicityOption("", 1, 1000);
      if (result.error) {
        this.showError("Can not get list ethnicity. Please try again later.");
        return;
      }
      // default ethicity = kinh
      this.listEthnicityOptions = result.items.map((i) => ({
        text: i.ethnicityCode,
        value: i.ethnicityID,
      }));
    },
    async renderListOccupationOptions() {
      var result = await PatientService.getOccupationOption("", 1, 1000);
      if (result.error) {
        this.showError("Can not get list occupation. Please try again later.");
        return;
      }
      this.listOccupationOptions = result.items.map((i) => ({
        text: i.occupationDescription,
        value: i.occupationID,
      }));
    },
    getFullName() {
      return this.personalInfo.fullName;
    },
  },
};
</script>

<style scoped>
.capitalize {
  text-transform: capitalize;
}
</style>