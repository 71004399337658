var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"d-block w-100"},[(_vm.loading)?_c('div',{staticClass:"d-flex align-center",attrs:{"align-center":""}},[_c('v-progress-circular',{staticClass:"mr-3",attrs:{"indeterminate":"","color":"primary"}}),_c('span',{staticClass:"primary--text"},[_vm._v("Checking ...")])],1):_vm._e(),(!_vm.loading)?_c('v-dialog',{attrs:{"width":"1200"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('span',_vm._g(_vm._b({staticClass:"text--subtitle primary--text",staticStyle:{"cursor":"pointer"}},'span',attrs,false),on),[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"primary"}},[_vm._v(" mdi-card-account-details-outline ")]),_c('span',[_vm._v(" "+_vm._s(_vm.$t("personalInfo.viewChecking"))+" ")])],1)]}}],null,false,141392047),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[(!_vm.loading)?_c('v-card',{attrs:{"elevation":"0"}},[_c('v-card-title',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v(" mdi-file-document-outline ")]),_vm._v(" "+_vm._s(_vm.$t("personalInfo.socialInsuranceInformation.title"))+" ")],1),_c('v-divider'),_c('v-sheet',{staticClass:"pa-2 mt-2",attrs:{"elevation":"0"}},[_c('v-alert',{attrs:{"dismissible":"","type":_vm.status.type,"icon":"mdi-alert"},model:{value:(_vm.status.show),callback:function ($$v) {_vm.$set(_vm.status, "show", $$v)},expression:"status.show"}},[_vm._v(" "+_vm._s(_vm.status.message)+" ")]),_c('v-row',{attrs:{"justify":"space-between"}},[_c('v-col',{attrs:{"cols":"6","sx":"12"}},[_c('CardInsurance',{attrs:{"title":_vm.$t('personalInfo.socialInsuranceInformation.status'),"listData":_vm.dataRender.status}}),_c('CardInsurance',{staticClass:"mt-3",attrs:{"title":_vm.$t('personalInfo.socialInsuranceInformation.patientInfo'),"listData":_vm.dataRender.information}}),_c('CardInsurance',{staticClass:"mt-3",attrs:{"title":_vm.$t('personalInfo.socialInsuranceInformation.note'),"listData":_vm.dataRender.note}})],1),_c('v-col',{attrs:{"cols":"6","sx":"12"}},[_c('CardInsurance',{attrs:{"title":_vm.$t(
                  'personalInfo.socialInsuranceInformation.currentInsuranceCard'
                ),"listData":_vm.dataRender.currentInsurance}}),_c('CardInsurance',{staticClass:"mt-3",attrs:{"title":_vm.$t(
                  'personalInfo.socialInsuranceInformation.transferAnotherInsuranceCard'
                ),"listData":_vm.dataRender.transferInsurance}})],1)],1)],1),_c('v-card-title',{staticClass:"font-weight-bold"},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"black"}},[_vm._v(" mdi-file-document-outline ")]),_vm._v(" "+_vm._s(_vm.$t("personalInfo.socialInsuranceInformation.historyExamination"))+" ")],1),_c('v-divider'),_c('v-sheet',{staticClass:"pa-2 mt-2",attrs:{"elevation":"0"}},[(_vm.emptyHistoryExamination() == false)?_c('v-row',_vm._l((_vm.dataRender.historyExamination),function(item,index){return _c('v-col',{key:index,attrs:{"cols":"4"}},[_c('CardInsurance',{attrs:{"listData":item}})],1)}),1):_vm._e()],1)],1):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }