<template>
  <v-tooltip top>
    <template v-slot:activator="{ on, attrs }">
      <v-chip
        v-bind="attrs"
        v-on="on"
        :class="{
          waiting: order.statusID === 1,
          'in-progress': order.statusID === 2,
          complete: order.statusID === 3,
        }"
        dark
        class="ma-1"
        @click="onChipClick(order)"
      >
        {{ order.name }}
      </v-chip>
    </template>
    <span v-html="order.tooltip"></span>
  </v-tooltip>
</template>

<script>
export default {
  props: {
    order: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  created() {
    this.transformItem();
  },
  methods: {
    transformItem() {
      var roomName = `Room ${this.order.roomName}`;
      var staffName = `Staff ${this.order.staffPerformName}`;
      var tooltip = `
        ${this.order.name}<br/>
        ${this.order.roomName} - ${this.order.staffPerformName}`;
      this.order.roomName = roomName;
      this.order.staffName = staffName;
      this.order.tooltip = tooltip;
    },
    onChipClick() {
      this.$emit("onChipClick", this.order);
    },
  },
};
</script>