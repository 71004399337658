<template>
  <v-menu
    v-if="optionPrint == OptionPrint.Hover"
    left
    transition="scale-transition"
    close-on-click
  >
    <template v-slot:activator="{ on, attrs }">
      <slot name="printer" :on="on" :attrs="attrs">
        <v-btn icon small v-bind="attrs" v-on="on">
          <v-icon dark title="Print discharge summary"> mdi-printer </v-icon>
        </v-btn>
      </slot>
    </template>
    <!-- List Menu Printing -->
    <v-list link>
      <v-list-item
        v-for="(itemMenuPrint, menuIndex) in menus"
        :key="menuIndex"
        style="cursor: pointer"
      >
        <v-list-item-title
          v-if="itemMenuPrint.childrens.length == 0"
          @click="printLink(itemMenuPrint.link, itemMenuPrint.text)"
        >
          {{ itemMenuPrint.text }}
        </v-list-item-title>
        <template v-else>
          <v-menu
            open-on-hover
            :close-on-click="true"
            left
            content-class="menu-childrens"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-list-item-title v-bind="attrs" v-on="on">
                {{ itemMenuPrint.text }}
              </v-list-item-title>
            </template>
            <v-list-item
              v-for="(itemChild, indexChild) in itemMenuPrint.childrens"
              :key="indexChild"
              link
            >
              <v-list-item-title
                v-text="itemChild.text"
                @click="printLink(itemChild.link, itemChild.text)"
                style="cursor: pointer"
              ></v-list-item-title>
            </v-list-item>
          </v-menu>
        </template>
      </v-list-item>
    </v-list>
  </v-menu>
  <div
    v-else-if="optionPrint == OptionPrint.Horizontal"
    class="flex-horizontal"
  >
    <div
      v-for="(itemMenuPrint, menuIndex) in menus"
      :key="menuIndex"
      @click="printLink(itemMenuPrint.link, itemMenuPrint.text)"
    >
      <slot name="btnPrint">
        <v-icon color="primary" class="mr-0">{{ itemMenuPrint.icon }}</v-icon>
        <a v-show="justIcon == false"> {{ itemMenuPrint.text }} </a>
      </slot>
    </div>
  </div>
  <div v-else-if="optionPrint == OptionPrint.Vertical" class="flex-vertical">
    <div
      v-for="(itemMenuPrint, menuIndex) in menus"
      :key="menuIndex"
      @click="printLink(itemMenuPrint.link, itemMenuPrint.text)"
    >
      <slot name="btnPrint">
        <v-icon color="primary" class="mr-0">{{ itemMenuPrint.icon }}</v-icon>
        <a v-show="justIcon == false"> {{ itemMenuPrint.text }} </a>
      </slot>
    </div>
  </div>
</template>

<script>
import { OptionPrint } from "@/plugins/constant";
import i18n from "@/plugins/i18n";

export default {
  props: {
    patientInfo: {
      type: Object,
      default: () => ({
        episodeRecordID: undefined,
        patientID: undefined,
      }),
    },
    childrensMenu: {
      type: Boolean,
      default: true,
    },
    optionPrint: {
      type: Number,
      default: OptionPrint.Hover,
    },
    listWantToPrint: {
      type: Array,
      default: () => [],
    },
    justIcon: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      OptionPrint,
      menus: [],
      menuPrinting: [
        {
          code: "discharge-summary",
          text: i18n.t("common.dishchargeSummary"),
          link:
            "/patient/${patientID}/prescription/${episodeRecordID}/discharge",
          icon: "mdi-file-document",
          childrens: [],
        },
        {
          code: "medicine-prescription",
          text: i18n.t("common.medicinePrescription"),
          link:
            "/patient/${patientID}/prescription/${episodeRecordID}/medicine",
          icon: "mdi-medical-bag",
          childrens: [],
        },
        {
          code: "glass-prescription",
          text: i18n.t("common.glassPrescription"),
          icon: "mdi-file-document-multiple",
          link: "",
          childrens: [
            {
              code: "rimmed-glass",
              text: i18n.t("common.glassPrescription"),
              link:
                "/patient/${patientID}/prescription/${episodeRecordID}/glass",
              icon: "mdi-file-document",
              childrens: [],
            },
            {
              code: "soft-cls-glass",
              text: i18n.t("common.softClClass"),
              link:
                "/patient/${patientID}/prescription/${episodeRecordID}/cls/soft",
              icon: "mdi-file-document",
              childrens: [],
            },
            {
              code: "hard-cls-glass",
              text: i18n.t("common.HardClClass"),
              link:
                "/patient/${patientID}/prescription/${episodeRecordID}/cls/hard",
              icon: "mdi-file-document",
              childrens: [],
            },
            {
              code: "orthok-cls-glass",
              text: i18n.t("common.OrthoKClClass"),
              link:
                "/patient/${patientID}/prescription/${episodeRecordID}/cls/orthok",
              icon: "mdi-file-document",
              childrens: [],
            },
          ],
        },
      ],
    };
  },
  created() {
    this.getListWantToPrint();
  },
  methods: {
    recursiveFunction(values = []) {
      for (var item of values) {
        if (item.childrens.length != 0) {
          this.recursiveFunction(item.childrens);
        }
        if (item.link != "") {
          this.menus.push(item);
        }
      }
      return false;
    },
    getListWantToPrint() {
      if (this.childrensMenu == true) {
        for (var item of this.menuPrinting) {
          this.menus.push(item);
        }
      } else if (this.childrensMenu == false) {
        if (this.listWantToPrint.length == 0) {
          this.recursiveFunction(this.menuPrinting);
        } else {
          this.recursiveFunction(this.menuPrinting);
          var temp = this.menus;
          this.menus = [];
          for (var item2 of temp) {
            if (this.listWantToPrint.includes(item2.code)) {
              this.menus.push(item2);
            }
          }
        }
      }
      //let patientID = this.$route.params.patientId;
      var menu = this.menus.map((i) => {
        if (i.childrens.length == 0) {
          i.link = i.link.replace(
            "${patientID}", 
            this.patientInfo.patientID);
          i.link = i.link.replace(
            "${episodeRecordID}",
            this.patientInfo.episodeRecordID
          );
        } else {
          let listPathChildrens = i.childrens.map((iChild) => {
            iChild.link = iChild.link.replace(
              "${patientID}",
              this.patientInfo.patientID
            );
            iChild.link = iChild.link.replace(
              "${episodeRecordID}",
              this.patientInfo.episodeRecordID
            );
            return iChild;
          });
          i.childrens = listPathChildrens;
        }
        return i;
      });
      return JSON.parse(JSON.stringify(menu));
    },
    printLink(link, title) {
      // close menu
      this.menuParent = false;
      this.menuChildren = false;
      // open link with new tab
      window.open(link, title, "width=800, height=500");
    },
  },
};
</script>

<style lang="scss" scoped>
.menu-childrens {
  background-color: #ffffff;
  z-index: 9999;
}
.flex-horizontal {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  cursor: pointer;
}
.flex-horizontal > div {
  text-align: center;
  margin: 10px;
  width: auto;
}
.flex-vertical {
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  cursor: pointer;
}
.flex-vertical > div {
  margin: 10px;
  width: auto;
}
</style>>