<template>
  <v-container fluid class="px-5 pb-0 pt-0" v-if="episodeRecordID">
    <div class="order-chip-container">
      <v-chip-group show-arrows>
        <OrderChip
          v-for="order in orders"
          :key="order.orderID"
          :order="order"
        />
      </v-chip-group>
    </div>
    <div class="admission-info-container">
      <p class="slider">
        <v-divider vertical class="patient-divide mx-4 ml-0" />
        <span>{{ $t("main.admissionInfoLine.admissionTime") }} : </span>
        <span class="font-weight-bold">{{
          formatDate(admissionInfo.admissionTime)
        }}</span>
        <v-divider vertical class="patient-divide mx-4" />
        <span>{{ $t("main.admissionInfoLine.admissionType") }} : </span>
        <span class="font-weight-bold">{{
          admissionInfo.admissionTypeName
        }}</span>
        <v-divider vertical class="patient-divide mx-4" />
        <span>{{ $t("main.admissionInfoLine.chiefComplaints") }} : </span>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <span
              v-bind="attrs"
              v-on="on"
              class="font-weight-bold mw-600"
              v-html="admissionInfo.chiefComplaints || 'None'"
            ></span>
          </template>
          <span v-html="admissionInfo.chiefComplaints || 'None'"></span>
        </v-tooltip>
      </p>
    </div>
  </v-container>
</template>

<script>
import moment from "moment";
import WorkListService from "@/services/work-list";
import OrderChip from "@/components/OrderChip";

export default {
  props: {
    episodeRecordID: {
      type: Number,
      default: function () {
        return parseInt(this.$route.query.episodeRecordID || 0);
      },
    },
  },
  components: {
    OrderChip,
  },
  data() {
    return {
      orders: [],
      admissionInfo: {},
    };
  },
  async mounted() {
    this.renderAdmissionInfo();
    this.sockets.common.on("OrderItemCompleted", this.onOrderItemCompleted);
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    formatDate(date) {
      if (!date) return "";
      return moment(date).format("HH:mm, DD-MM-YYYY");
    },
    onOrderItemCompleted(orderID) {
      orderID = parseInt(orderID);
      var order = this.orders.find((o) => o.orderID == orderID);
      if (order) {
        order.statusID = 3;
      }
    },
    async renderAdmissionInfo() {
      if (!this.episodeRecordID) return;
      var result = await WorkListService.GetByEpisodeRecordID(
        this.episodeRecordID
      );
      if (result.error) {
        this.showError("Cannot get work list info! Please try again later!");
        return;
      }
      var { admissionInfo, orders } = result || {};
      var items = JSON.parse(JSON.stringify(orders || []));
      this.admissionInfo = admissionInfo || {};
      this.orders = items || [];
      this.$emit("onAdmissionLoaded", result);
    },
  },
};
</script>

<style scoped lang="scss">
.mw-600 {
  max-width: 600px;
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  height: 18px;
}
.order-chip-container {
  display: inline-block;
  max-width: 45%;
  overflow: hidden;
}
.admission-info-container {
  display: inline-block;
  max-width: 50%;
  overflow: hidden;
  p.slider {
    width: 1000vw;
    margin-top: 5px;
    margin-bottom: 10px;
  }
}
</style>