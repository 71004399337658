<template>
  <v-sheet rounded="lg" v-if="isShow">
    <v-card-title>
      {{ $t("personalInfo.lastVisitOf") }}
      <b class="ml-2">{{ patientInfo.patientName }}</b
      >:</v-card-title
    >
    <!-- list booking table -->
    <v-skeleton-loader
      :loading="skeletonLoadingTable"
      height="500"
      type="table"
    >
      <v-data-table
        :headers="headers"
        :items="items"
        :options.sync="tableOpts"
        :server-items-length="totals"
        :footer-props="tableFooterProps"
        @update:items-per-page="renderLastVisits"
        @update:page="onChangePagination"
        disable-sort
        class="app-custom-table"
        mobile-breakpoint="0"
      >
        <template v-slot:no-data>
          {{ $t("main.report.dosentPatient") }}
        </template>
        <template v-slot:[`item.procedure`]="{ item }">
          <span v-if="!item.procedure.length"> NA </span>
          <span v-else v-for="(name, index) in item.procedure" :key="index">
            {{ name }}. <br />
          </span>
        </template>
        <template v-slot:[`item.printer`]="{ item }">
          <GeneralPrint
            :childrensMenu="childrensMenu"
            :optionPrint="OptionPrint.Hover"
            :patientInfo="item.patientInfo"
            :listWantToPrint="listWantToPrint"
            :justIcon="justIcon"
          />
        </template>
      </v-data-table>
    </v-skeleton-loader>
    <!-- end list booking table -->
  </v-sheet>
</template>
<script>
import GeneralPrint from "@/components/prescription/GeneralPrint";
import PatientService from "@/services/patient";
import i18n from "@/plugins/i18n";

import {
  TABLE_ROWS_PER_PAGE,
  TABLE_OPTS,
  AuditType,
  OptionPrint,
} from "@/plugins/constant";

import moment from "moment";

export default {
  components: {
    GeneralPrint,
  },
  props: {
    itemsPerPage: {
      type: Number,
      default: 6,
    },
    typeTarget: {
      type: Number,
      default: AuditType.Service,
    },
  },
  data() {
    return {
      OptionPrint,
      childrensMenu: true,
      justIcon: true,
      listWantToPrint: [],
      isShow: false,
      // placeholder lazy loading
      skeletonLoadingTable: true,
      // booking list table
      headers: [
        { text: i18n.t("personalInfo.lastVisit"), value: "admissionTime" },
        { text: i18n.t("personalInfo.clinic"), value: "clinicName" },
        // { text: "Patient", value: "patientName" },
        { text: i18n.t("personalInfo.service"), value: "serviceName" },
        {
          text: i18n.t("personalInfo.primaryDiagnosis"),
          value: "diagnosisString",
        },
      ],
      totals: 0,
      items: [],
      tableOpts: { ...TABLE_OPTS, itemsPerPage: this.itemsPerPage },
      tableFooterProps: {
        itemsPerPageOptions: TABLE_ROWS_PER_PAGE,
      },
      patientInfo: {
        patientID: null,
        patientName: null,
      },
    };
  },
  watch: {
    patientID: {
      handler() {
        this.renderLastVisits();
      },
    },
  },
  methods: {
    showError(message) {
      this.$toast.error(message);
    },
    hide() {
      this.isShow = false;
    },
    async renderLastVisits(patientID, patientName) {
      if (!patientID) return;
      patientName = patientName || "";
      // cache local data
      this.patientInfo = {
        patientID,
        patientName,
      };
      this.skeletonLoadingTable = true;
      this.isShow = true;

      const { page, itemsPerPage } = this.tableOpts;
      var keySearch = "";

      var result = await PatientService.searchLastVisits(
        patientID,
        keySearch,
        page,
        itemsPerPage
      );
      this.skeletonLoadingTable = false;

      if (result.error) {
        this.showError("Cannot get list bookings. Please try again later!");
        return;
      }
      this.items = result.items.map((item) => {
        if (this.typeTarget == AuditType.Service) {
          item.serviceName =
            item.serviceItems && item.serviceItems[0].description;
        } else if (this.typeTarget == AuditType.Procedure) {
          item["procedure"] = item.serviceItems
            .filter((i) => i.typeID == this.typeTarget)
            .map((i) => i.description);
        }
        item.admissionTime = moment(item.admissionTime).format("DD/MM/YYYY");
        item.diagnosisString =
          item.diagnosisResults
            .filter((d) => d.diagnosisCode == "Primary")
            .map((d) => d.icDxDescription)
            .join(" + ") || "NA";
        item.patientInfo = {
          episodeRecordID: item.episodeRecordIDs[0],
          patientID: patientID,
        };
        return item;
      });
      console.log(this.items);
      this.totals = result.totals;
      if (this.typeTarget != AuditType.Service) {
        this.headers = [
          { text: "Last Visit", value: "admissionTime" },
          { text: "Clinic", value: "clinicName" },
          {
            text:
              this.typeTarget == AuditType.Procedure
                ? "Procedure"
                : "Accessment",
            value:
              this.typeTarget == AuditType.Procedure
                ? "procedure"
                : "accessment",
          },
          { text: "Primary Diagnosis", value: "diagnosisString" },
          { text: "Print", value: "printer" },
        ];
      }
    },
    async onChangePagination(value) {
      this.tableOpts.page = value;
      var { patientID, patientName } = this.patientInfo;
      await this.renderLastVisits(patientID, patientName);
    },
  },
};
</script>

<style scoped>
</style>