import { render, staticRenderFns } from "./AdmissionInfoLine.vue?vue&type=template&id=2247dc70&scoped=true&"
import script from "./AdmissionInfoLine.vue?vue&type=script&lang=js&"
export * from "./AdmissionInfoLine.vue?vue&type=script&lang=js&"
import style0 from "./AdmissionInfoLine.vue?vue&type=style&index=0&id=2247dc70&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2247dc70",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChipGroup } from 'vuetify/lib/components/VChipGroup';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChipGroup,VContainer,VDivider,VTooltip})
